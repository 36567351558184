import React, { memo, useCallback, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import { useQueryParam, StringParam } from "use-query-params"


import SEO from "components/SEO"
import Layout from "components/layout"
import ModuleRender from "components/ModuleRender"
import Container from "components/container"
import { H3, H4, P } from "components/headings"
import SearchIcon from "images/icons/search-white.svg"
import Hero from "images/illustrations/knowledge-center-hero.svg"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import Link from "components/Link"
import Button from "components/button"


import DataCollectionHero from "images/illustrations/data-collection-hero.svg"
import GettingStartedHero from "images/illustrations/getting-started-hero.svg"
import SuccessStrategiesHero from "images/illustrations/success-strategies-hero.svg"
import UsingLatanaHero from "images/illustrations/using-latana-hero.svg"
import WhatIsLatanaHero from "images/illustrations/what-is-latana-hero.svg"


const query = graphql`
	query {
		allContentfulKnowledgePost(filter: { node_locale: { eq: "en-US" } }) {
			edges {
				node {
					title
					slug
					category
					content {
						content
					}
					seo {
						keywords
					}
				}
			}
		}
		contentfulPage(slug: { eq: "knowledge-center" }) {
			hideFooterDemo
			seo {
				...SEO
			}
			sections {
				... on ContentfulAlternatingCards {
					...AlternatingCards
				}
				... on ContentfulBrandImagesSection {
					...BrandImages
				}
				... on ContentfulBrandImagesWithReviews {
					...BrandImagesWithReviews
				}
				... on ContentfulLandingSections {
					...Section
				}
				... on ContentfulReferencesSection {
					...ReferencesSection
				}
			}
		}
	}
`


const SearchKnowledge = styled.div`
	padding-top: 70px;
	padding-bottom: 36px;
	background: rgb(102, 42, 188);

	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;

		${mediaBreakpoint(BreakPoints.SM)} {
			flex-direction: row;
		}

		div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			position: relative;

			h3 {
				color: white;
			}
		}
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		height: 355px;

		img {
			margin-right: 7rem;
		}
	}
`
const SearchContainer = styled.div`
	max-width: 626px;

	h1 {
		color: #fff;
	}
`
const SearchInput = styled.input`
	color: white;
	background: no-repeat url(${SearchIcon}) right center;
	border: 0;
	border-bottom: 1px solid white;
	font-size: 2.4rem;
	line-height: 4.8rem;
	width: 100%;
	margin: 8rem 0;

	::placeholder {
		color: white;
		opacity: 1;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		margin: 0;
	}
`
const ResultsContainer = styled.div`
	position: relative;
	height: 0;
	overflow: visible;
`
const Results = styled.div`
	position: absolute;
	top: 2rem;
	left: 0;
	width: 100%;
	height: auto;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: flex-start !important;
	padding: 4rem;
	border-radius: 1rem;
	box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);
	transform: translateY(50%);

	a {
		color: var(--text-color);
		text-decoration: none;
		font-weight: 800;
		font-size: 2.4rem;
		line-height: 2.4rem;

		&:hover {
			text-decoration: underline;
		}
		&:not(:first-of-type) {
			margin-top: 1.6rem;
		}
	}
`
const ArticlesOverview = styled.div`
	padding: 8rem 0;
`


const Categories = styled.div`
	display: flex;
	overflow: scroll;
`
const Category = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0 0 100%;

	button {
		width: auto;
		color: black !important;
		border: 1px solid black !important;
	}
	h4 {
		text-transform: capitalize;
	}
	a {
		color: black;
		font-size: 1.8rem;
		line-height: 1.5;
		text-decoration: none;
		margin-bottom: 1.4rem;

		&:hover {
			text-decoration: underline;
		}
		&:last-of-type {
			margin-top: auto;
		}
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		flex: 0 0 33.33%;
	}
`
const categories = ["what-is-latana", "getting-started", "using-latana", "data-collection", "success-strategies"]


const categoryImages = new Map()
categoryImages.set("what-is-latana", WhatIsLatanaHero)
categoryImages.set("getting-started", GettingStartedHero)
categoryImages.set("using-latana", UsingLatanaHero)
categoryImages.set("data-collection", DataCollectionHero)
categoryImages.set("success-strategies", SuccessStrategiesHero)


const KnowlegdeCenter = () => {
	const { contentfulPage, allContentfulKnowledgePost } = useStaticQuery(query)
	const knowledgePosts = allContentfulKnowledgePost.edges
	const { sections, seo } = contentfulPage
	const [searchParam = "", setSearchParam] = useQueryParam("search", StringParam)


	const handleSearch = useCallback(e => {
		const { value } = e.target
		setSearchParam(value || undefined)
	}, [])


	const searchText = useMemo(() => {
		if (searchParam.length > 2) {
			try {
				return new RegExp(searchParam, "i")
			} catch (_e) {
				return null
			}
		} else {
			return null
		}
	}, [searchParam])


	const filtered = useMemo(() => {
		if (searchParam.length > 2) {
			return knowledgePosts.filter(post => {
				return searchText.test(post.node.title) || post.node?.seo?.keywords?.find(keyword => searchText.test(keyword))
			})
		}


		return null
	}, [knowledgePosts, searchParam])
	
	return (
		<div>
			<SEO {...seo} />
			<Layout knowledgeCentre headerConfig={{ variant: "transparent-white" }} footerConfig={{ variant: "default", hideDemo:false, hideNewsletter:false}}>
				<SearchKnowledge>
					<Container>
						<SearchContainer>
							<H3 as="h1">What are you looking for?</H3>
							<SearchInput
								id="search-knowledge-center"
								type="search"
								placeholder="Search Anything"
								onChange={handleSearch}
								defaultValue={searchParam || ""}
								aria-label="Search Knowledge Center articles"
							/>
							{filtered && filtered.length > 0 && (
								<ResultsContainer>
									<Results>
										{filtered.map(result => {
											return (
												<Link className="category-link" to={`/knowledge-center/${result.node.slug}`}>
													{result.node.title}
												</Link>
											)
										})}
									</Results>
								</ResultsContainer>
							)}
						</SearchContainer>
						<img src={Hero} alt="Knowledge Center" />
					</Container>
				</SearchKnowledge>
				<ArticlesOverview>
					<Container>
						<H3 as="h2">Latana Knowledge Center</H3>
						<P>Everything you need to know about the best brand tracking solution in the world.</P>
						<Categories>
							{categories.map(category => {
								const links = knowledgePosts.filter(({ node }) => node.category === category).slice(0, 3)


								return (
									<Category>
										<img src={categoryImages.get(category)} alt="" />
										<H4>{category.replace(/-/g, " ")}</H4>
										{links.map(post => (
											<Link to={`/knowledge-center/${post.node.slug}`}>{post.node.title}</Link>
										))}
										<Button
											type="button"
											label="Learn More"
											variant="hollow-border"
											link={
												{
													url: `/knowledge-center/${links[0].node.slug}`,
												} as any
											}
										/>
									</Category>
								)
							})}
						</Categories>
					</Container>
				</ArticlesOverview>
				<ModuleRender sections={sections} />
			</Layout>
		</div>
	)
}


export default memo(KnowlegdeCenter)